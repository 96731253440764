try {
    require('@popperjs/core');
    window.bootstrap = require('bootstrap');
    // window.$ = window.jQuery = require('jquery');
} catch (e) {
}
var searchpopover = new bootstrap.Popover(document.querySelector('.search-popover'), {
    container: 'body',
    html: true,
    placement: 'left',
    trigger: 'click',
    sanitize: false
})

window.onscroll = function() { myFunction() };
var navbar = document.getElementById("navbar");
var body = document.body;
var sticky = navbar.offsetTop;
var sticky = 44;

// Add the sticky class to the navbar when you reach its scroll position. Remove "sticky" when you leave the scroll position
function myFunction() {
    if (window.pageYOffset >= sticky) {
        navbar.classList.add("sticky")
        body.classList.add("down")
    } else {
        navbar.classList.remove("sticky");
        body.classList.remove("down")
    }
}
